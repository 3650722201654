<template>
	<main class="page-container" id="page-live-bridge">
		<div class="live-component container">
			<div class="bridge-title">
				<time class="date">{{ 
					brcInfo === null 
					? '' 
					: brcInfo.BRC_SCHDG_BGNG_DT?.substring(5, 7) + '월 ' 
					+ brcInfo.BRC_SCHDG_BGNG_DT?.substring(8, 10) + '일 ' 
					+ brcInfo.BRC_SCHDG_BGNG_DT?.substring(11, 13) + ':' 
					+ brcInfo.BRC_SCHDG_BGNG_DT?.substring(14, 16) 
				}}</time>
				<h2 class="title">{{ brcInfo === null ? '' : brcInfo.BRC_TIT }}</h2>
			</div>
			<div class="bridge-cover">
				<img :src="brcInfo === null ? '' : brcInfo.BRC_ADNOTC_IMG_URL" alt="" @error="handleImgError" />
				<div class="cover-content">
					<span class="text">{{ remainLivTime }} 후 라이브 시작</span>
				</div>
			</div>
			<div class="bridge-body">
				<div class="live-info-wrapper">
					<div class="bridge-seller"><span class="seller-name">가치삽시다 TV</span></div>
					<div class="bridge-notice">
						<toggle-switch 
							id="sbptnSwitch" 
							:propModel="sbptnInfo" 
							:value="'Y'"
							class="color-orange" 
							label="알림받기" 
							@changefunc="changeFuncLiveSbptn()"
						/>
					</div>
					<div v-if="parseInt(brcInfo?.BRC_STS_CD) >= 10" class="bridge-actions">
						<button type="button" class="button-default is-primary btn-share" @click="onClickSharePopupButton">
							<i class="icon-share"></i>
						</button>
					</div>
				</div>
				<section class="live-product">
					<header class="section-header"><h3 class="title">라이브 소개 상품</h3></header>
					<div class="grid-container product-container">
						<ul class="grid-list">
							<li v-for="(product, idx) in brcInfo.goods" :key="'gdsList_' + idx" class="grid-item">
								<article class="product-card">
									<div class="product-image">
										<a :href="product.GDS_PCOM_URL" class="image-link" target="_blank" rel=”noopener”>
											<img :src="product.GDS_IMG_URL" alt="" loading="lazy" @error="handleImgError" />
										</a>
										<div class="image-badge"></div>
									</div>
									<div class="product-info">
										<div class="title">
											<a :href="product.GDS_PCOM_URL" class="title-link" target="_blank" rel=”noopener”>
												<div class="text">{{ product.GDS_NM }}</div>
											</a>
										</div>
										<div class="price">
											<span class="price-current">{{ addThousandComma(product.GDS_PRC) }}<em>원</em></span>
										</div>
									</div>
								</article>
							</li>
						</ul>
					</div>
				</section>
			</div>
		</div>
		<!-- 공유 팝업 -->
		<live-share-pop v-if="sharePopupVisible" @close="onCloseSharePopupButton"></live-share-pop>
		<!-- //공유 팝업 -->
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { 
	ACT_GET_BRC_INFO, 
	ACT_GET_LIVE_SBPTN,
	ACT_SAVE_LIVE_SBPTN,
	} from '@/store/_act_consts';
import { 
	MUT_SHOW_CONFIRM, 
	MUT_SET_RETURN_ROUTE,
} from '@/store/_mut_consts';

import { getItem } from '@/assets/js/utils';

import errorImg from '@/assets/img/common/error.png';
import ToggleSwitch from '@/components/common/ToggleSwitch';
import LiveSharePop from '@/components/live/LiveSharePop'

//;
export default {
	name: 'LiveComing',
	components: { 
		ToggleSwitch, 
		LiveSharePop 
	},
	data: () => ({
		brcInfo: {},

		remainLivTime: '00:00:00',
		sbptnTrgtTypCd: [],
		sbptnInfo: [],
		sharePopupVisible: false,

		toggle1: [],
	}),
	computed: {
		...mapGetters('auth', ['session']),
	},
	watch: {
		session: {
			handler(newVal, oldVal) {
				if (!!newVal && !!newVal.userId) {
					this.getLiveSbptn();
				}
			},
			immediate: true,
		},
	},
	created() {},
	mounted() {
		this.getBrcInfo();
		this.timerId = setInterval(this.checkRemainLiveTime, 1000);
	},
	methods: {
		// promise ========================================================================================
		async getBrcInfo() {
			await this.$store
				.dispatch(`broadcast/${ACT_GET_BRC_INFO}`, {
						// brcInfoNo: 1685,
						brcInfoNo: this.$route.params.brcInfoNo,
						brcTyp: 1,
					})
				.then((res) => {
					if ("00" === res.data.RESULT_CD) {
						this.brcInfo = res.data.VIEW;
					}
				})
				.catch((err) => {
					console.error(err)
				});
		},

		async getLiveSbptn() {
			const reqParam = {
				userId: this.session.userId,
				sbptnTrgtTypCd: "300",
			};
				
			await this.$store
				.dispatch(`sbptn/${ACT_GET_LIVE_SBPTN}`, reqParam)
				.then((res) => {
					const sbptnYn = getItem(res)?.useYn || "N";
					const _sbptnInfo = sbptnYn === "Y" ? [sbptnYn] : ["N"];
					this.sbptnInfo = _sbptnInfo;
				});
		},
		async changeFuncLiveSbptn(val) {
			const nofuncCallback = () => {
				document.getElementById("sbptnSwitch").checked = false;
			};
			this.verifyUserSession(nofuncCallback);

			let reqBody = {
				userId: this.session.userId,
			};

			// 삭제
			if (this.sbptnInfo[0] === "Y") {
				const param = {
					html: true,
					title: '알림을 해제하시겠습니까?',
					showCancelButton: true,
					yesfunc: () => {
						reqBody["useYn"] = "N";
						this.saveLiveSbptn(reqBody);
					},
					nofunc: () => {
						document.getElementById("sbptnSwitch").checked = true;
					}
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
			}
			// 신규 및 수정
			else {
				reqBody["useYn"] = "Y";
				this.saveLiveSbptn(reqBody);
			}
		},
		async saveLiveSbptn(reqBody) {
			await this.$store
				.dispatch(`sbptn/${ACT_SAVE_LIVE_SBPTN}`, reqBody)
				.then((res) => {
					if (res.result.success) {
						this.sbptnInfo = [reqBody.useYn];
					}
					else {
						console.info(res.result.message);
					}
				})
				.catch((err) => console.log(err));
		},

		// html event ========================================================================================
		onClickSharePopupButton() {
			this.changeSharePopupVisible(true);
		},
		onCloseSharePopupButton() {
			this.changeSharePopupVisible(false);
		},
		changeSharePopupVisible(param) {
			this.sharePopupVisible = param;
		},

		// custome fn ========================================================================================
		handleImgError(e) {
			e.target.src = errorImg;
		},
		checkRemainLiveTime() {
			const arr = this.brcInfo.BRC_SCHDG_BGNG_DT.split(/[- :]/);
			const brcSchdgBgngDt = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);

			const currDt = new Date();
			const diffTime = Math.floor((brcSchdgBgngDt - currDt) / 1000);
			const second = diffTime % 60;
			const minute = ((diffTime - second) / 60) % 60;
			const hour = (diffTime - second - minute * 60) / 60 / 60;

			this.remainLivTime = hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0') + ':' + second.toString().padStart(2, '0');

			if (hour <= 0 && minute <= 0 && second <= 0) {
				clearInterval(this.timerId);
				this.$router.push({ name: 'LiveView', params: { brcInfoNo: this.$route.params.brcInfoNo }});
			}
		},

		addThousandComma(number) {
			if (typeof number === 'undefined' || number === null) {
				return 0;
			}
			
			const numberStr = number.toString();
			if (numberStr.length <= 3) {
				return numberStr;
			}
			else if (numberStr.substring(0, 1) === '-' && numberStr.length === 4) {
				return numberStr;
			}
			else {
				let commaNumberStr = '';
				let i;
				for (i = numberStr.length - 3; i > 0; i = i-3) {
					commaNumberStr = ',' + numberStr.substring(i, i+3) + commaNumberStr;
				}

				if (i+3 > 0) {
					commaNumberStr = numberStr.substring(0, i+3) + commaNumberStr;
				}
				return commaNumberStr;
			}
		},

		verifyUserSession(nofuncCallback) {
			if (!this.session || !this.session.userId) {
				const param = {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {
						if (!!nofuncCallback) nofuncCallback();
					},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}
			return true;
		},
	},
};
</script>
