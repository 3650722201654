<template>
	<label class="toggle-switch-wrap" :class="className">
		<div class="toggle-switch-container">
			<!-- <input type="checkbox" v-model="model" :value="value" :id="id" @change="$emit('changefunc')" /> -->
			<input type="checkbox" v-model="model" :value="value" :id="id" />
			<div class="toggle-switch pull-right" aria-hidden="true">
				<div class="toggle-switch-label">
					<div class="toggle-switch-inner"></div>
					<div class="toggle-switch-switch"></div>
				</div>
			</div>
			<span v-if="label !== '' && label !== undefined" class="toggle-switch-label__after">{{ label }}</span>
		</div>
	</label>
</template>

<script>
export default {
	name: 'ToggleSwitch',
	props: {
		id: null,
		className: Array,
		value: null,
		label: String,
		propModel: Array,
		// model: Array,
	},
	computed: {
		model: {
			get() {
				// console.log('get');
				return this.propModel;
			},
			set(newVal) {
				this.$emit('changefunc', newVal);
			},
		},
	},
	methods: {},
};
</script>
